import { post } from '../../utils/axios'
import { getLocalStorage } from '../../utils/helpers'

export const loginAdmin = async (request , currentHeader) => {
  return post(
    'v1/admin/login',
    { ...request?.data },
    {
      headers: currentHeader,
    }
  )
}


export const logoutAdmin = async (request) => {
  return post('v1/admin/logout', { ...request?.data }, {})
}

export const isAdmin = () => {
  const authData = getLocalStorage('auth')
  return authData ? (authData.user.isAdmin === 1 ? true : false) : false
}



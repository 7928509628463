import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  CHANGE_THEME,
  SET_PROPERTY,
  PROPERTY_DELETE_MODAL_OPEN,
  PROPERTY_DELETE_MODAL_CLOSE,
  PROPERTY_CHANGES_STATUS,
  SET_DEAL,
  DEAL_DELETE_MODAL_OPEN,
  DEAL_DELETE_MODAL_CLOSE,
  DEAL_CHANGES_STATUS,
  SET_MATCH,
  MATCH_DELETE_MODAL_OPEN,
  MATCH_DELETE_MODAL_CLOSE,
  MATCH_CHANGES_STATUS,
  SET_BLOG_CATEGORY,
} from '../utils/actions'

const main_reducer = (state, action) => {
  switch (action.type) {
    // sidebar
    case SIDEBAR_OPEN:
      return { ...state, isSidebarOpen: true }
    case SIDEBAR_CLOSE:
      return { ...state, isSidebarOpen: false }
    // theme
    case CHANGE_THEME:
      return { ...state, theme: action.payload }
    // property
    case SET_PROPERTY:
      return { ...state, currentProperty: action.payload }
    case PROPERTY_DELETE_MODAL_OPEN:
      return { ...state, isDeletePropertyModalOpen: true }
    case PROPERTY_DELETE_MODAL_CLOSE:
      return { ...state, isDeletePropertyModalOpen: false }
    case PROPERTY_CHANGES_STATUS:
      return { ...state, propertyChangesStatus: action.payload }
    // deal
    case SET_DEAL:
      return { ...state, currentDeal: action.payload }
    case DEAL_DELETE_MODAL_OPEN:
      return { ...state, isDeleteDealModalOpen: true }
    case DEAL_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteDealModalOpen: false }
    case DEAL_CHANGES_STATUS:
      return { ...state, dealChangesStatus: action.payload }
    // deal
    case SET_MATCH:
      return { ...state, currentMatch: action.payload }
    case MATCH_DELETE_MODAL_OPEN:
      return { ...state, isDeleteMatchModalOpen: true }
    case MATCH_DELETE_MODAL_CLOSE:
      return { ...state, isDeleteMatchModalOpen: false }
    case MATCH_CHANGES_STATUS:
      return { ...state, matchChangesStatus: action.payload }
    // blog category
    case SET_BLOG_CATEGORY:
      return { ...state, currentBlogCategory: action.payload }
    default:
      return state
  }
}

export default main_reducer

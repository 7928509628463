
import React, { useContext,useReducer } from "react";


// custom
import reducer from "../reducers/admin_reducer";
import {
  SET_POST,
  POST_DELETE_MODAL_OPEN,
  POST_DELETE_MODAL_CLOSE,
  POST_CHANGES_STATUS,
  SET_USER,
  USER_DELETE_MODAL_OPEN,
  USER_DELETE_MODAL_CLOSE,
  USER_CHANGES_STATUS,
  SET_FORM,
  FORM_DELETE_MODAL_OPEN,
  FORM_DELETE_MODAL_CLOSE,
  FORM_CHANGES_STATUS,
  SET_OFFER,
  OFFER_DELETE_MODAL_OPEN,
  OFFER_DELETE_MODAL_CLOSE,
  OFFER_CHANGES_STATUS,
  SET_REQUEST,
  REQUEST_DELETE_MODAL_OPEN,
  REQUEST_DELETE_MODAL_CLOSE,
  REQUEST_CHANGES_STATUS,
  SET_AGENTS_NOTIFICATION_DATA,
  AGENTS_NOTIFICATION_MODAL_OPEN,
  AGENTS_NOTIFICATION_MODAL_CLOSE,
  SET_FILTER,
  FILTER_MODAL_OPEN,
  FILTER_MODAL_CLOSE,
  FILTER_CHANGES_STATE,
  SET_FILTER_COMMENT,
  FILTER_COMMENT_MODAL_OPEN,
  FILTER_COMMENT_MODAL_CLOSE,
  SET_FILTER_CHNAGE_BASE_STATUS,
  FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN,
  FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE,
  HOLDER_MODAL_OPEN,
  HOLDER_MODAL_CLOSE,
  SET_FILTER_PROP,
  FILTER_MODAL_OPEN_PROP,
  FILTER_MODAL_CLOSE_PROP,
  FILTER_CHANGES_STATE_PROP,
  SET_FILTER_COMMENT_PROP,
  FILTER_COMMENT_MODAL_OPEN_PROP,
  FILTER_COMMENT_MODAL_CLOSE_PROP,
  SET_FILTER_CHNAGE_BASE_STATUS_PROP,
  FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP,
  FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP,
  HOLDER_PROP_MODAL_OPEN,
  HOLDER_PROP_MODAL_CLOSE
} from '../utils/actions'


const initialContext= {
	// post
	currentPost : {},
	setPost : (() => {}),
	isDeletePostModalOpen : false,
	openDeletePostModal : (() => {}),
	closeDeletePostModal : (() => {}),
	postChangesStatus : false,
	postChangesEvent : (() => {}),
	// user
	currentUser : {},
	setUser : (() => {}),
	isDeleteUserModalOpen : false,
	openDeleteUserModal : (() => {}),
	closeDeleteUserModal : (() => {}),
	userChangesStatus : false,
	userChangesEvent : (() => {}),
	// form
	currentForm : {},
	setForm : (() => {}),
	isDeleteFormModalOpen : false,
	openDeleteFormModal : (() => {}),
	closeDeleteFormModal : (() => {}),
	formChangesStatus : false,
	formChangesEvent : (() => {}),
	// offer
	currentOffer : {},
	setOffer : (() => {}),
	isDeleteOfferModalOpen : false,
	openDeleteOfferModal : (() => {}),
	closeDeleteOfferModal : (() => {}),
	offerChangesStatus : false,
	offerChangesEvent : (() => {}),
	// request
	currentRequest : {},
	setRequest : (() => {}),
	isDeleteRequestModalOpen : false,
	openDeleteRequestModal : (() => {}),
	closeDeleteRequestModal : (() => {}),
	requestChangesStatus : false,
	requestChangesEvent : (() => {}),
	// Agents Notification
	agentsNotificationData : {},
	setAgentsNotificationData : (() => {}),
	isAgentsNotificationModalOpen : false,
	openAgentsNotificationModal : (() => {}),
	closeAgentsNotificationModal : (() => {}),
	// ===== request
	// filter
	currentFilter : {
		sort_by: [],
    	sort_order : [],
		id : '',
		created_at : null,
		move_at : null,
		type : [],
		rooms : [],
		price : '',
		owner : null,
		ownerType : [],
		propertyType : [],
		location : null,
		status : [],
		status_matching  : [],
		assign_to: null,
		assign_due: null,
		matchingPrecentage : '',
		proposed_status : null
	},
	setFilter : (() => {}),
	isFiltertModalOpen : false,
	openFilterModal : (() => {}),
	closeFilterModal : (() => {}),
	filterChangesState : false,
	filterChangesEvent : (() => {}),
	// filter comment
	currentFilterComment : {},
	setFilterComment : (() => {}),
	isFiltertCommentModalOpen : false,
	openFilterCommentModal : (() => {}),
	closeFilterCommentModal : (() => {}),
	// filter change base status
	currentFilterChangedBaseStatus : {},
	setFilterChangeBaseStatus : (() => {}),
	isFiltertChangeBaseStatusModalOpen : false,
	openFilterChangeBaseStatusModal : (() => {}),
	closeFilterChangeBaseStatusModal : (() => {}),
	// holder
	isHolderModalOpen : false,
	openHolderModal : (() => {}),
	closeHolderModal : (() => {}),
	//========= property
	// filter
	currentFilterProp : {
		sort_by: [],
    	sort_order : [],
		id : '',
		created_at : null,
		move_at : null,
		type : [],
		rooms : [],
		price : '',
		owner : null,
		ownerType : [],
		propertyType : [],
		location : null,
		status : [],
		status_matching  : [],
		assign_to: null,
		assign_due: null,
		matchingPrecentage : '',
		percentageThreshold : 0,
		proposed_status : null
	},
	setFilterProp : (() => {}),
	isFiltertPropModalOpen : false,
	openFilterPropModal : (() => {}),
	closeFilterPropModal : (() => {}),
	filterPropChangesState : false,
	filterPropChangesEvent : (() => {}),
	// filter comment
	currentFilterPropComment : {},
	setFilterPropComment : (() => {}),
	isFiltertPropCommentModalOpen : false,
	openFilterPropCommentModal : (() => {}),
	closeFilterPropCommentModal : (() => {}),
	// filter change base status
	currentFilterPropChangedBaseStatus : {},
	setFilterPropChangeBaseStatus : (() => {}),
	isFiltertPropChangeBaseStatusModalOpen : false,
	openFilterPropChangeBaseStatusModal : (() => {}),
	closeFilterPropChangeBaseStatusModal : (() => {}),
	// holder
	isHolderPropModalOpen : false,
	openHolderPropModal : (() => {}),
	closeHolderPropModal : (() => {}),
};

const AdminContext = React.createContext(initialContext);

export const AdminProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialContext);

	// post
	const setPost = (post) => {
		dispatch({ type: SET_POST, payload: post });
	};
	const openDeletePostModal = () => {
		dispatch({ type: POST_DELETE_MODAL_OPEN });
	}
	const closeDeletePostModal = () => {
		dispatch({ type: POST_DELETE_MODAL_CLOSE });
	}
	const postChangesEvent = (propStatus) => {
		dispatch({ type: POST_CHANGES_STATUS ,  payload: propStatus });
	}
	// user
	const setUser = (user) => {
		dispatch({ type: SET_USER, payload: user });
	};
	const openDeleteUserModal = () => {
		dispatch({ type: USER_DELETE_MODAL_OPEN });
	}
	const closeDeleteUserModal = () => {
		dispatch({ type: USER_DELETE_MODAL_CLOSE });
	}
	const userChangesEvent = (userStatus) => {
		dispatch({ type: USER_CHANGES_STATUS ,  payload: userStatus });
	}
	// form
	const setForm = (form) => {
		dispatch({ type: SET_FORM, payload: form });
	};
	const openDeleteFormModal = () => {
		dispatch({ type: FORM_DELETE_MODAL_OPEN });
	}
	const closeDeleteFormModal = () => {
		dispatch({ type: FORM_DELETE_MODAL_CLOSE });
	}
	const formChangesEvent = (formStatus) => {
		dispatch({ type: FORM_CHANGES_STATUS ,  payload: formStatus });
	}
	// offer
	const setOffer = (offer) => {
		dispatch({ type: SET_OFFER, payload: offer });
	};
	const openDeleteOfferModal = () => {
		dispatch({ type: OFFER_DELETE_MODAL_OPEN });
	}
	const closeDeleteOfferModal = () => {
		dispatch({ type: OFFER_DELETE_MODAL_CLOSE });
	}
	const offerChangesEvent = (offerStatus) => {
		dispatch({ type: OFFER_CHANGES_STATUS ,  payload: offerStatus });
	}
	// request
	const setRequest = (request) => {
		dispatch({ type: SET_REQUEST, payload: request });
	};
	const openDeleteRequestModal = () => {
		dispatch({ type: REQUEST_DELETE_MODAL_OPEN });
	}
	const closeDeleteRequestModal = () => {
		dispatch({ type: REQUEST_DELETE_MODAL_CLOSE });
	}
	const requestChangesEvent = (requestStatus) => {
		dispatch({ type: REQUEST_CHANGES_STATUS ,  payload: requestStatus });
	}
	// Agents Notification
	const setAgentsNotificationData = (notificationData) => {
		dispatch({ type: SET_AGENTS_NOTIFICATION_DATA, payload: notificationData });
	};
	const openAgentsNotificationModal = () => {
		dispatch({ type: AGENTS_NOTIFICATION_MODAL_OPEN });
	}
	const closeAgentsNotificationModal = () => {
		dispatch({ type: AGENTS_NOTIFICATION_MODAL_CLOSE });
	}
	// =========== property =============
	// filter
	const setFilterProp = (filterProp) => {
		dispatch({ type: SET_FILTER_PROP, payload: filterProp });
	};
	const openFilterPropModal = () => {
		dispatch({ type: FILTER_MODAL_OPEN_PROP });
	}
	const closeFilterPropModal = () => {
		dispatch({ type: FILTER_MODAL_CLOSE_PROP });
	}
	const filterPropChangesEvent = (filterPropStatus) => {
		dispatch({ type: FILTER_CHANGES_STATE_PROP ,  payload: filterPropStatus });
	}
	// filter comment
	const setFilterPropComment = (filterPropComment) => {
		dispatch({ type: SET_FILTER_COMMENT_PROP, payload: filterPropComment });
	};
	const openFilterPropCommentModal = () => {
		dispatch({ type: FILTER_COMMENT_MODAL_OPEN_PROP});
	}
	const closeFilterPropCommentModal = () => {
		dispatch({ type: FILTER_COMMENT_MODAL_CLOSE_PROP});
	}
	// filter change base status
	const setFilterPropChangeBaseStatus = (filterPropBaseStatus) => {
		dispatch({ type: SET_FILTER_CHNAGE_BASE_STATUS_PROP, payload: filterPropBaseStatus });
	};
	const openFilterPropChangeBaseStatusModal = () => {
		dispatch({ type: FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN_PROP});
	}
	const closeFilterPropChangeBaseStatusModal = () => {
		dispatch({ type: FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE_PROP});
	}
	// holder
	const openHolderPropModal = () => {
		dispatch({ type: HOLDER_PROP_MODAL_OPEN});
	}
	const closeHolderPropModal = () => {
		dispatch({ type: HOLDER_PROP_MODAL_CLOSE});
	}
	// =========== request =============
	// filter
	const setFilter = (filter) => {
		dispatch({ type: SET_FILTER, payload: filter });
	};
	const openFilterModal = () => {
		dispatch({ type: FILTER_MODAL_OPEN });
	}
	const closeFilterModal = () => {
		dispatch({ type: FILTER_MODAL_CLOSE });
	}
	const filterChangesEvent = (filterStatus) => {
		dispatch({ type: FILTER_CHANGES_STATE ,  payload: filterStatus });
	}
	// filter comment
	const setFilterComment = (filterComment) => {
		dispatch({ type: SET_FILTER_COMMENT, payload: filterComment });
	};
	const openFilterCommentModal = () => {
		dispatch({ type: FILTER_COMMENT_MODAL_OPEN});
	}
	const closeFilterCommentModal = () => {
		dispatch({ type: FILTER_COMMENT_MODAL_CLOSE});
	}
	// filter change base status
	const setFilterChangeBaseStatus = (filterBaseStatus) => {
		dispatch({ type: SET_FILTER_CHNAGE_BASE_STATUS, payload: filterBaseStatus });
	};
	const openFilterChangeBaseStatusModal = () => {
		dispatch({ type: FILTER_CHNAGE_BASE_STATUS_MODAL_OPEN});
	}
	const closeFilterChangeBaseStatusModal = () => {
		dispatch({ type: FILTER_CHNAGE_BASE_STATUS_MODAL_CLOSE});
	}
	// holder
	const openHolderModal = () => {
		dispatch({ type: HOLDER_MODAL_OPEN});
	}
	const closeHolderModal = () => {
		dispatch({ type: HOLDER_MODAL_CLOSE});
	}

	return (
		<AdminContext.Provider
			value={{
				...state,
				// post
				setPost,
				openDeletePostModal,
				closeDeletePostModal,
				postChangesEvent,
				// user
				setUser,
				openDeleteUserModal,
				closeDeleteUserModal,
				userChangesEvent,
				// form
				setForm,
				openDeleteFormModal,
				closeDeleteFormModal,
				formChangesEvent,
				// offer
				setOffer,
				openDeleteOfferModal,
				closeDeleteOfferModal,
				offerChangesEvent,
				// request
				setRequest,
				openDeleteRequestModal,
				closeDeleteRequestModal,
				requestChangesEvent,
				// Agents Notification
				setAgentsNotificationData,
				openAgentsNotificationModal,
				closeAgentsNotificationModal,
				// ========== property =====
				// filter
				setFilterProp,
				openFilterPropModal,
				closeFilterPropModal,
				filterPropChangesEvent,
				// filter comment
				setFilterPropComment,
				openFilterPropCommentModal,
				closeFilterPropCommentModal,
				// filter change base status
				setFilterPropChangeBaseStatus,
				openFilterPropChangeBaseStatusModal,
				closeFilterPropChangeBaseStatusModal,
				// holder
				openHolderPropModal,
				closeHolderPropModal,
				// ========== request =====
				// filter
				setFilter,
				openFilterModal,
				closeFilterModal,
				filterChangesEvent,
				// filter comment
				setFilterComment,
				openFilterCommentModal,
				closeFilterCommentModal,
				// filter change base status
				setFilterChangeBaseStatus,
				openFilterChangeBaseStatusModal,
				closeFilterChangeBaseStatusModal,
				// holder
				openHolderModal,
				closeHolderModal
			}}
		>
			{children}
		</AdminContext.Provider>
	);
};
// make sure use
export const useAdminContext = () => {
	return useContext(AdminContext);
};

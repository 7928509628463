import { get, post } from "../utils/axios";
import { getLocalStorage } from "../utils/helpers";


export const getUserLocal = () => {
  const authData = getLocalStorage('auth')
  return authData ? authData.user : {}
}

export const getDashboardData = async () => {
  return get(`v1/profile/dashboard`)
}

export const updateProfile = async (request) => {
  return post('v1/profile/update', { ...request?.data }, {})
}

export const changeEmailToken = async (request) => {
  return post('v1/profile/email/token', { ...request?.data }, {})
}

export const changeEmailUpdate = async (request) => {
  return post('v1/profile/email/change', { ...request?.data }, {})
}

export const changePassword = async (request) => {
  return post('v1/profile/change-password', { ...request?.data }, {})
}

export const changeImage = async (request) => {
  return post(
    'v1/profile/change-image',
    { ...request?.data },
    {
      headers: {
        processData: false,
        mimeType: 'multipart/form-data',
        'content-type': 'multipart/form-data',
      },
    }
  )
}

export const setUserPlan = async (request) => {
  return post('v1/profile/setPlan', { ...request?.data }, {})
}

export const getUserPlan = async () => {
  return get('v1/profile/getPlan')
}

export const getUserTickets = async (paramsStr) => {
  return get(`v1/profile/tickets?${paramsStr}`)
}

export const getUserTicketDetails = async (ticketID) => {
  return get(`v1/profile/tickets/${ticketID}`)
}

export const addTicket = async (request) => {
  return post(
    'v1/profile/tickets',
    { ...request?.data },
    {
      headers: {
        processData: false,
        mimeType: 'multipart/form-data',
        'content-type': 'multipart/form-data',
      },
    }
  )
}

export const addComment = async (ticketID, request) => {
  return post(
    `v1/profile/tickets/${ticketID}/comment`,
    { ...request?.data },
    {
      headers: {
        processData: false,
        mimeType: 'multipart/form-data',
        'content-type': 'multipart/form-data',
      },
    }
  )
}
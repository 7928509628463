import './src/assets/styles/global.css'
import React, { useEffect } from 'react'

// lib
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// custom
import { MainProvider } from './src/contexts/main_context'
import { isAdmin } from './src/services/admin/auth.service'
import { AdminProvider } from './src/contexts/admin_context'
import { getUserLocal } from './src/services/user.service'
import { matching_settings_access_users } from './src/utils/constants'

export const wrapPageElement = ({ element, props }) => {
  const isLoggedIn = Cookies.get('token')
  const currentUser = getUserLocal()
  const clientPages = [
    '/profile/dashboard',
    '/profile/settings',
    '/profile/wishlist',
  ]
  const clientAuthPages = [
    '/register',
    '/login',
    '/verify',
    '/reset-password',
    '/forget-password',
  ]

  const adminAccessPages = [
    '/backbone/settings/matching-settings',
    '/backbone/matching/requests-analysis',
    '/backbone/matching/properties-analysis',
  ]

  // Check if the page is in the admin section and not the login page
  const isAdminPage =
    props.location.pathname.startsWith('/backbone/') &&
    props.location.pathname !== '/backbone/'

  // access to admin pags without login
  if (isAdminPage && !isLoggedIn) {
    navigate('/login')
  }
  // access to admin pages as client
  if (
    (isAdminPage && isLoggedIn && !isAdmin()) ||
    (props.location.pathname === '/backbone' && isLoggedIn && !isAdmin())
  ) {
    navigate('/')
  }
  // after login as admin, you not allowed to move to admin login
  if (props.location.pathname === '/backbone' && isAdmin()) {
    navigate('/backbone/dashboard/usersDashboard')
  }
  // after login as client, you not allowed to move to client auth pages
  if (
    clientAuthPages.includes(props.location.pathname) &&
    isLoggedIn
  ) {
    navigate('/')
  }

  // after login as client, you not allowed to move to client auth pages
  if (clientPages.includes(props.location.pathname) && !isLoggedIn) {
    localStorage.removeItem('auth')
    Cookies.remove('token')
    window.location.pathname = '/login'
  }

  if (props.location.pathname === '/verify' && ( currentUser === null || currentUser === undefined ||  Object.keys(currentUser).length == 0 ) ) {
    navigate('/login')
  }

  if (
    adminAccessPages.includes(props.location.pathname) &&
    isAdmin() &&
    !matching_settings_access_users.includes(currentUser.id)
  ) {
    navigate('/backbone/dashboard/usersDashboard')
  }

  return (
    <AdminProvider>
      <MainProvider>
        <ToastContainer />
        {element}
      </MainProvider>
    </AdminProvider>
  )
}

// google config & lemon sqeezy
export const onClientEntry = async () => {
  // Lazy load Google API script
  const { initGoogleAPI } = await import('./src/utils/googleAPI')
  // Initialize Google API
  initGoogleAPI()

  // // Lazy Lemon Squeezy API script
  // const { initLemonSqueezy } = await import('./src/utils/lemonSqueezy')
  // // Initialize Lemon Squeezy
  // initLemonSqueezy()
  // Load and initialize lemon app script
  const script = document.createElement('script')
  script.src = 'https://app.lemonsqueezy.com/js/lemon.js'
  script.async = true
  // script.defer = true
  script.loading = 'lazyOnload'
  document.body.appendChild(script)
}

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
// }